import Command from '@ckeditor/ckeditor5-core/src/command';
import {createRhSwiper} from "./RhSwiperUtils";

export default class InsertRhSwiperCommand extends Command {
    execute() {
        this.editor.model.change( writer => {
            this.editor.model.insertContent( createRhSwiper( writer ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'RhSwiper' );

        this.isEnabled = allowedIn !== null;
    }
}
