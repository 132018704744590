import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import WidgetToolbarRepository from "@ckeditor/ckeditor5-widget/src/widgettoolbarrepository";

import InsertRhDropdownCommand from './insertRhDropdownCommand';
import './theme/style.css';

export default class RhDropdownEditing extends Plugin {
    static get requires() {
        return [ Widget, WidgetToolbarRepository ];
    }
    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( 'insertRhDropdown', new InsertRhDropdownCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'RhDropdown', {
            isObject: true,
            allowWhere: '$block'
        } );

        schema.register( 'RhDropdownHandler', {
            isObject: true,
            allowIn: 'RhDropdown',
        } );

        schema.register( 'RhDropdownTitle', {
            isLimit: true,
            allowIn: 'RhDropdownHandler',
            allowContentOf: '$block'
        } );

        schema.register( 'RhDropdownContent', {
            isLimit: true,
            allowIn: 'RhDropdown',
            allowContentOf: '$root'
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        // <RhDropdown> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'RhDropdown',
            view: {
                name: 'section',
                classes: 'RhDropdown'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'RhDropdown',
            view: {
                name: 'section',
                classes: 'RhDropdown'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'RhDropdown',
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( 'section', { class: 'RhDropdown' } );

                return toWidget( section, viewWriter, { label: 'RhDropdown Widget' } );
            }
        } );

        // <RhDropdownHandler> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'RhDropdownHandler',
            view: {
                name: 'div',
                classes: 'RhDropdownHandler',
            }
        } );
        conversion.for( 'dataDowncast' ).elementToStructure( {
            model: 'RhDropdownHandler',
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( 'div', { class: 'RhDropdownHandler', role: 'button' }, [
                    viewWriter.createSlot(node => node.is( 'element', 'RhDropdownTitle' )),
                    viewWriter.createContainerElement('div', {class:'RhDropdownHandlerIcon'}, [
                        viewWriter.createContainerElement('i', {class:'icon-arrow_2_down icon-m'})
                    ]),
                ] );
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'RhDropdownHandler',
            view: {
                name: 'div',
                classes: 'RhDropdownHandler',
            }
        } );

        // <RhDropdownTitle> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'RhDropdownTitle',
            view: {
                name: 'div',
                classes: 'RhDropdownTitle',
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'RhDropdownTitle',
            view: {
                name: 'div',
                classes: 'RhDropdownTitle subtitle1'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'RhDropdownTitle',
            view: ( modelElement, { writer: viewWriter } ) => {
                const div = viewWriter.createEditableElement( 'div', { class: 'RhDropdownTitle' } );
                return toWidgetEditable( div, viewWriter );
            }
        } );

        // <RhDropdownContent> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'RhDropdownContent',
            view: {
                name: 'div',
                classes: 'RhDropdownContent',
            }
        } );
        conversion.for( 'dataDowncast' ).elementToStructure( {
            model: 'RhDropdownContent',
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( 'div', { class: 'RhDropdownContent' }, [
                    viewWriter.createContainerElement('div', { class: 'RhDropdownContentContainer' }, [
                        viewWriter.createSlot()
                    ])
                ] );

            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'RhDropdownContent',
            view: ( modelElement, { writer: viewWriter } ) => {
                const div = viewWriter.createEditableElement( 'div', { class: 'RhDropdownContent' } );
                return toWidgetEditable( div, viewWriter );
            }
        } );
    }
}
