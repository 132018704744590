import Command from '@ckeditor/ckeditor5-core/src/command';

export default class MoveSlideUpCommand extends Command {
    execute() {
        const model = this.editor.model;
        const slide = model.document.selection.getSelectedElement().findAncestor('RhSwiperSlide');

        model.change( writer => {
            writer.insert( slide, slide.previousSibling, 'before')
        });
    }

    refresh() {
        const model = this.editor.model;
        const element = model.document.selection.getSelectedElement();

        if(null == element){
            this.isEnabled = false;
            return;
        }

        const slide = element.findAncestor('RhSwiperSlide');

        this.isEnabled = (null != slide) && (null != slide.previousSibling);
    }
}
