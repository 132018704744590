import Command from '@ckeditor/ckeditor5-core/src/command';
import {createRhSlide} from "./RhSwiperUtils";

export default class InsertRhSwiperSlideCommand extends Command {
    execute() {
        const model = this.editor.model;
        const slide = model.document.selection.getSelectedElement().findAncestor('RhSwiperSlide');

        model.change( writer => {
            writer.append( createRhSlide( writer ), slide.parent );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const element = model.document.selection.getSelectedElement();

        if(null == element){
            this.isEnabled = false;
            return;
        }

        const slide = model.document.selection.getSelectedElement().findAncestor('RhSwiperSlide');

        this.isEnabled = null != slide;
    }
}
