import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import alignTop from '@ckeditor/ckeditor5-core/theme/icons/align-top.svg'
import alignBottom from '@ckeditor/ckeditor5-core/theme/icons/align-bottom.svg'
import addSlide from './theme/icons/plus-circle.svg'
import deleteSlide from './theme/icons/x-circle.svg'

export default class RhSwiperUI extends Plugin {
    init() {
        const editor = this.editor;
        const t = editor.t;

        editor.ui.componentFactory.add( 'RhSwiper', locale => {
            const command = editor.commands.get( 'insertRhSwiper' );

            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: t( 'Slider' ),
                withText: true,
                tooltip: true
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            this.listenTo( buttonView, 'execute', () => editor.execute( 'insertRhSwiper' ) );

            return buttonView;
        } );

        editor.ui.componentFactory.add( 'RhSwiperUp', locale => {
            const command = editor.commands.get( 'moveSlideUpCommand' );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                icon: alignTop,
                tooltip: true,
                label: t( 'Remonter la slide' ),
                withText: false,
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            this.listenTo( buttonView, 'execute', () => editor.execute( 'moveSlideUpCommand'));

            return buttonView;
        } );

        editor.ui.componentFactory.add( 'RhSwiperDown', locale => {
            const command = editor.commands.get( 'moveSlideDownCommand' );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                icon: alignBottom,
                tooltip: true,
                label: t( 'Descendre la slide' ),
                withText: false,
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            this.listenTo( buttonView, 'execute', () => editor.execute( 'moveSlideDownCommand'));

            return buttonView;
        } );

        editor.ui.componentFactory.add( 'RhSwiperAdd', locale => {
            const command = editor.commands.get( 'insertRhSwiperSlide' );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                icon: addSlide,
                tooltip: true,
                label: t( 'Ajouter une slide' ),
                withText: false,
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            this.listenTo( buttonView, 'execute', () => editor.execute( 'insertRhSwiperSlide'));

            return buttonView;
        } );

        editor.ui.componentFactory.add( 'RhSwiperRemove', locale => {
            const command = editor.commands.get( 'deleteRhSwiperSlide' );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                icon: deleteSlide,
                tooltip: true,
                label: t( 'Supprimer la slide' ),
                withText: false,
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            this.listenTo( buttonView, 'execute', () => editor.execute( 'deleteRhSwiperSlide'));

            return buttonView;
        } );
    }
}
