import read from 'rrg-font/icons/read.svg';
import log_out from 'rrg-font/icons/log_out.svg';
import save from 'rrg-font/icons/save.svg';
import print from 'rrg-font/icons/print.svg';
import send from 'rrg-font/icons/send.svg';
import file_download from 'rrg-font/icons/file_download.svg';
import folder from 'rrg-font/icons/folder.svg';
import files from 'rrg-font/icons/files.svg';
import sheet from 'rrg-font/icons/sheet.svg';
import list_sheet from 'rrg-font/icons/list_sheet.svg';
import faourite_sheet from 'rrg-font/icons/faourite_sheet.svg';
import car_sheet from 'rrg-font/icons/car_sheet.svg';
import email_sheet from 'rrg-font/icons/email_sheet.svg';
import pdf_sheet from 'rrg-font/icons/pdf_sheet.svg';
import settings_sheet from 'rrg-font/icons/settings_sheet.svg';
import cookies from 'rrg-font/icons/cookies.svg';
import usb from 'rrg-font/icons/usb.svg';
import reload from 'rrg-font/icons/reload.svg';
import refresh from 'rrg-font/icons/refresh.svg';
import edit from 'rrg-font/icons/edit.svg';
import check_list from 'rrg-font/icons/check_list.svg';
import bullet_list from 'rrg-font/icons/bullet_list.svg';
import check_list_ok from 'rrg-font/icons/check_list_ok.svg';
import ok from 'rrg-font/icons/ok.svg';
import no from 'rrg-font/icons/no.svg';
import supDelete from 'rrg-font/icons/delete.svg';
import apps from 'rrg-font/icons/apps.svg';
import app from 'rrg-font/icons/app.svg';
import home from 'rrg-font/icons/home.svg';
import settings from 'rrg-font/icons/settings.svg';
import filter_1 from 'rrg-font/icons/filter_1.svg';
import filter_2 from 'rrg-font/icons/filter_2.svg';
import favourite from 'rrg-font/icons/favourite.svg';
import bookmark from 'rrg-font/icons/bookmark.svg';
import new_bookmark from 'rrg-font/icons/new_bookmark.svg';
import view from 'rrg-font/icons/view.svg';
import search from 'rrg-font/icons/search.svg';
import zoom from 'rrg-font/icons/zoom.svg';
import deZoom from 'rrg-font/icons/de-zoom.svg';
import increase_text from 'rrg-font/icons/increase_text.svg';
import decrease_text from 'rrg-font/icons/decrease_text.svg';
import touch from 'rrg-font/icons/touch.svg';
import menu from 'rrg-font/icons/menu.svg';
import add from 'rrg-font/icons/add.svg';
import more from 'rrg-font/icons/more.svg';
import alert from 'rrg-font/icons/alert.svg';
import information from 'rrg-font/icons/information.svg';
import question from 'rrg-font/icons/question.svg';
import comment from 'rrg-font/icons/comment.svg';
import dialog from 'rrg-font/icons/dialog.svg';
import user from 'rrg-font/icons/user.svg';
import discussion from 'rrg-font/icons/discussion.svg';
import group from 'rrg-font/icons/group.svg';
import contact from 'rrg-font/icons/contact.svg';
import idea from 'rrg-font/icons/idea.svg';
import photo from 'rrg-font/icons/photo.svg';
import video from 'rrg-font/icons/video.svg';
import play from 'rrg-font/icons/play.svg';
import pause from 'rrg-font/icons/pause.svg';
import stop from 'rrg-font/icons/stop.svg';
import previous from 'rrg-font/icons/previous.svg';
import next from 'rrg-font/icons/next.svg';
import minimize from 'rrg-font/icons/minimize.svg';
import full_screen from 'rrg-font/icons/full_screen.svg';
import expand from 'rrg-font/icons/expand.svg';
import soud from 'rrg-font/icons/soud.svg';
import no_sound from 'rrg-font/icons/no_sound.svg';
import music from 'rrg-font/icons/music.svg';
import podcast from 'rrg-font/icons/podcast.svg';
import download from 'rrg-font/icons/download.svg';
import upload from 'rrg-font/icons/upload.svg';
import links from 'rrg-font/icons/links.svg';
import link_out from 'rrg-font/icons/link_out.svg';
import share_1 from 'rrg-font/icons/share_1.svg';
import share_2 from 'rrg-font/icons/share_2.svg';
import arrow_1_left from 'rrg-font/icons/arrow_1_left.svg';
import arrow_1_right from 'rrg-font/icons/arrow_1_right.svg';
import arrow_1_up from 'rrg-font/icons/arrow_1_up.svg';
import arrow_1_down from 'rrg-font/icons/arrow_1_down.svg';
import arrow_1_up_left from 'rrg-font/icons/arrow_1_up_left.svg';
import arrow_1_up_right from 'rrg-font/icons/arrow_1_up_right.svg';
import arrow_1_down_right from 'rrg-font/icons/arrow_1_down_right.svg';
import arrow_1_down_left from 'rrg-font/icons/arrow_1_down_left.svg';
import arrow_2_left from 'rrg-font/icons/arrow_2_left.svg';
import arrow_2_right from 'rrg-font/icons/arrow_2_right.svg';
import arrow_2_up from 'rrg-font/icons/arrow_2_up.svg';
import arrow_2_down from 'rrg-font/icons/arrow_2_down.svg';
import arrow_2_up_left from 'rrg-font/icons/arrow_2_up_left.svg';
import arrow_2_up_right from 'rrg-font/icons/arrow_2_up_right.svg';
import arrow_2_down_right from 'rrg-font/icons/arrow_2_down_right.svg';
import arrow_2_down_left from 'rrg-font/icons/arrow_2_down_left.svg';
import slide from 'rrg-font/icons/slide.svg';
import scroll from 'rrg-font/icons/scroll.svg';
import phone from 'rrg-font/icons/phone.svg';
import mobile from 'rrg-font/icons/mobile.svg';
import tablet from 'rrg-font/icons/tablet.svg';
import laptop from 'rrg-font/icons/laptop.svg';
import desktop from 'rrg-font/icons/desktop.svg';
import television from 'rrg-font/icons/television.svg';
import wifi from 'rrg-font/icons/wifi.svg';
import carSend from 'rrg-font/icons/car-send.svg';
import car_received from 'rrg-font/icons/car_received.svg';
import car_ko from 'rrg-font/icons/car_ko.svg';
import car_ok from 'rrg-font/icons/car_ok.svg';
import calendar from 'rrg-font/icons/calendar.svg';
import reminder from 'rrg-font/icons/reminder.svg';
import alarm from 'rrg-font/icons/alarm.svg';
import stopwatch from 'rrg-font/icons/stopwatch.svg';
import answer_in_4_hours from 'rrg-font/icons/answer_in_4_hours.svg';
import data from 'rrg-font/icons/data.svg';
import chart from 'rrg-font/icons/chart.svg';
import organisation from 'rrg-font/icons/organisation.svg';
import planet from 'rrg-font/icons/planet.svg';
import factory from 'rrg-font/icons/factory.svg';
import foundy from 'rrg-font/icons/foundy.svg';
import basket from 'rrg-font/icons/basket.svg';
import credit_card from 'rrg-font/icons/credit_card.svg';
import euro from 'rrg-font/icons/euro.svg';
import money from 'rrg-font/icons/money.svg';
import coins from 'rrg-font/icons/coins.svg';
import reset_coins from 'rrg-font/icons/reset_coins.svg';
import saing from 'rrg-font/icons/saing.svg';
import calculator from 'rrg-font/icons/calculator.svg';
import promo from 'rrg-font/icons/promo.svg';
import sales from 'rrg-font/icons/sales.svg';
import shop_bag from 'rrg-font/icons/shop_bag.svg';
import box from 'rrg-font/icons/box.svg';
import car_servicing from 'rrg-font/icons/car_servicing.svg';
import welcom from 'rrg-font/icons/welcom.svg';
import carrestitution from 'rrg-font/icons/carrestitution.svg';
import car_dealership from 'rrg-font/icons/car_dealership.svg';
import safety from 'rrg-font/icons/safety.svg';
import tailored from 'rrg-font/icons/tailored.svg';
import flag from 'rrg-font/icons/flag.svg';
import target from 'rrg-font/icons/target.svg';
import pannel from 'rrg-font/icons/pannel.svg';
import compas from 'rrg-font/icons/compas.svg';
import pin from 'rrg-font/icons/pin.svg';
import motorway from 'rrg-font/icons/motorway.svg';
import countryside from 'rrg-font/icons/countryside.svg';
import city from 'rrg-font/icons/city.svg';
import parking from 'rrg-font/icons/parking.svg';
import mobility_help from 'rrg-font/icons/mobility_help.svg';
import tap_gesture_1 from 'rrg-font/icons/tap_gesture_1.svg';
import tap_gesture_2 from 'rrg-font/icons/tap_gesture_2.svg';
import tap_gesture_3 from 'rrg-font/icons/tap_gesture_3.svg';
import double_tab_gesture_1 from 'rrg-font/icons/double_tab_gesture_1.svg';
import double_tab_gesture_2 from 'rrg-font/icons/double_tab_gesture_2.svg';
import double_tab_gesture_3 from 'rrg-font/icons/double_tab_gesture_3.svg';
import swipe_gesture_1 from 'rrg-font/icons/swipe_gesture_1.svg';
import swipe_gesture_2 from 'rrg-font/icons/swipe_gesture_2.svg';
import swipe_gesture_3 from 'rrg-font/icons/swipe_gesture_3.svg';
import hold_gesture_1 from 'rrg-font/icons/hold_gesture_1.svg';
import hold_gesture_2 from 'rrg-font/icons/hold_gesture_2.svg';
import hold_gesture_3 from 'rrg-font/icons/hold_gesture_3.svg';
import scroll_gesture_1 from 'rrg-font/icons/scroll_gesture_1.svg';
import scroll_gesture_2 from 'rrg-font/icons/scroll_gesture_2.svg';
import scroll_gesture_3 from 'rrg-font/icons/scroll_gesture_3.svg';
import scale_gesture_1 from 'rrg-font/icons/scale_gesture_1.svg';
import scale_gesture_2 from 'rrg-font/icons/scale_gesture_2.svg';
import scale_gesture_3 from 'rrg-font/icons/scale_gesture_3.svg';
import drag_gesture_1 from 'rrg-font/icons/drag_gesture_1.svg';
import drag_gesture_2 from 'rrg-font/icons/drag_gesture_2.svg';
import drag_gesture_3 from 'rrg-font/icons/drag_gesture_3.svg';
import split_and_rotate_gesture_1 from 'rrg-font/icons/split_and_rotate_gesture_1.svg';
import split_and_rotate_gesture_2 from 'rrg-font/icons/split_and_rotate_gesture_2.svg';
import split_and_rotate_gesture_3 from 'rrg-font/icons/split_and_rotate_gesture_3.svg';
import eco_2_chart from 'rrg-font/icons/eco_2_chart.svg';
import eco_2 from 'rrg-font/icons/eco_2.svg';
import fuel_station from 'rrg-font/icons/fuel_station.svg';
import fuel_excellent from 'rrg-font/icons/fuel_excellent.svg';
import fuel_very_good from 'rrg-font/icons/fuel_very_good.svg';
import fuel_good from 'rrg-font/icons/fuel_good.svg';
import fuel_low from 'rrg-font/icons/fuel_low.svg';
import fuel_very_low from 'rrg-font/icons/fuel_very_low.svg';
import no_fuel from 'rrg-font/icons/no_fuel.svg';
import fuel_compare from 'rrg-font/icons/fuel_compare.svg';
import hybrid from 'rrg-font/icons/hybrid.svg';
import plugging_hybrid from 'rrg-font/icons/plugging_hybrid.svg';
import battery from 'rrg-font/icons/battery.svg';
import battery_leasing from 'rrg-font/icons/battery_leasing.svg';
import electric_power from 'rrg-font/icons/electric_power.svg';
import electric_plug from 'rrg-font/icons/electric_plug.svg';
import not_plugged from 'rrg-font/icons/not_plugged.svg';
import plugged_ok from 'rrg-font/icons/plugged_ok.svg';
import standard_charging_port from 'rrg-font/icons/standard_charging_port.svg';
import rapide_charging_port from 'rrg-font/icons/rapide_charging_port.svg';
import wall_charging_port from 'rrg-font/icons/wall_charging_port.svg';
import electric_vehicule from 'rrg-font/icons/electric_vehicule.svg';
import battery_excellent from 'rrg-font/icons/battery_excellent.svg';
import battery_very_good from 'rrg-font/icons/battery_very_good.svg';
import battery_good from 'rrg-font/icons/battery_good.svg';
import battery_low from 'rrg-font/icons/battery_low.svg';
import battery_very_low from 'rrg-font/icons/battery_very_low.svg';
import no_battery from 'rrg-font/icons/no_battery.svg';
import engine from 'rrg-font/icons/engine.svg';
import electric_engine from 'rrg-font/icons/electric_engine.svg';
import kw_engine from 'rrg-font/icons/kw_engine.svg';
import oil_engine from 'rrg-font/icons/oil_engine.svg';
import engine_capacity from 'rrg-font/icons/engine_capacity.svg';
import car from 'rrg-font/icons/car.svg';
import car_park from 'rrg-font/icons/car_park.svg';
import car_range from 'rrg-font/icons/car_range.svg';
import hatchback from 'rrg-font/icons/hatchback.svg';
import sedan from 'rrg-font/icons/sedan.svg';
import etate from 'rrg-font/icons/etate.svg';
import mpv from 'rrg-font/icons/mpv.svg';
import suv from 'rrg-font/icons/suv.svg';
import pickUp from 'rrg-font/icons/pick-up.svg';
import coupe from 'rrg-font/icons/coupe.svg';
import roadster from 'rrg-font/icons/roadster.svg';
import race from 'rrg-font/icons/race.svg';
import passenger_cdv from 'rrg-font/icons/passenger_cdv.svg';
import cdv from 'rrg-font/icons/cdv.svg';
import shuttle from 'rrg-font/icons/shuttle.svg';
import compact_van from 'rrg-font/icons/compact_van.svg';
import minibus from 'rrg-font/icons/minibus.svg';
import large_van from 'rrg-font/icons/large_van.svg';
import chassisCab from 'rrg-font/icons/chassis-cab.svg';
import dump_truck from 'rrg-font/icons/dump_truck.svg';
import campervan from 'rrg-font/icons/campervan.svg';
import acceleration from 'rrg-font/icons/acceleration.svg';
import braking from 'rrg-font/icons/braking.svg';
import prgression from 'rrg-font/icons/prgression.svg';
import renault_rent from 'rrg-font/icons/renault_rent.svg';
import second_hand from 'rrg-font/icons/second_hand.svg';
import refresh_configuration from 'rrg-font/icons/refresh_configuration.svg';
import start_again from 'rrg-font/icons/start_again.svg';
import interior from 'rrg-font/icons/interior.svg';
import custom from 'rrg-font/icons/custom.svg';
import favourite_car from 'rrg-font/icons/favourite_car.svg';
import intervention from 'rrg-font/icons/intervention.svg';
import mergency from 'rrg-font/icons/mergency.svg';
import crash from 'rrg-font/icons/crash.svg';
import breakdown from 'rrg-font/icons/breakdown.svg';
import car_elevator from 'rrg-font/icons/car_elevator.svg';
import car_review from 'rrg-font/icons/car_review.svg';
import draining from 'rrg-font/icons/draining.svg';
import mechanical_1 from 'rrg-font/icons/mechanical_1.svg';
import mechanical_2 from 'rrg-font/icons/mechanical_2.svg';
import spray_gun from 'rrg-font/icons/spray_gun.svg';
import paint_bucket from 'rrg-font/icons/paint_bucket.svg';
import spray from 'rrg-font/icons/spray.svg';
import spare_parts from 'rrg-font/icons/spare_parts.svg';
import car_body from 'rrg-font/icons/car_body.svg';
import type_and_rim from 'rrg-font/icons/type_and_rim.svg';
import braking_1 from 'rrg-font/icons/braking_1.svg';
import tyre from 'rrg-font/icons/tyre.svg';
import check_tyre from 'rrg-font/icons/check_tyre.svg';
import pumping from 'rrg-font/icons/pumping.svg';
import damper from 'rrg-font/icons/damper.svg';
import piston from 'rrg-font/icons/piston.svg';
import double_piston from 'rrg-font/icons/double_piston.svg';
import timing_belt from 'rrg-font/icons/timing_belt.svg';
import oil_filter from 'rrg-font/icons/oil_filter.svg';
import exhaust from 'rrg-font/icons/exhaust.svg';
import check_oil from 'rrg-font/icons/check_oil.svg';
import cooling_liquid from 'rrg-font/icons/cooling_liquid.svg';
import steering_wheel from 'rrg-font/icons/steering_wheel.svg';
import gearbox_1 from 'rrg-font/icons/gearbox_1.svg';
import gearbox_2 from 'rrg-font/icons/gearbox_2.svg';
import gearbox_3 from 'rrg-font/icons/gearbox_3.svg';
import gearbox_4 from 'rrg-font/icons/gearbox_4.svg';
import weight from 'rrg-font/icons/weight.svg';
import keys from 'rrg-font/icons/keys.svg';
import sellerie from 'rrg-font/icons/sellerie.svg';
import air_conditionning from 'rrg-font/icons/air_conditionning.svg';
import speeometer from 'rrg-font/icons/speeometer.svg';
import windshield from 'rrg-font/icons/windshield.svg';
import windshield_wipers from 'rrg-font/icons/windshield_wipers.svg';
import windshield_wipers_ok from 'rrg-font/icons/windshield_wipers_ok.svg';
import door from 'rrg-font/icons/door.svg';
import drame from 'rrg-font/icons/drame.svg';
import snow_box from 'rrg-font/icons/snow_box.svg';
import box_1 from 'rrg-font/icons/box_1.svg';
import luggages from 'rrg-font/icons/luggages.svg';
import payload from 'rrg-font/icons/payload.svg';
import bike from 'rrg-font/icons/bike.svg';
import headlights from 'rrg-font/icons/headlights.svg';
import dimmed_headlights from 'rrg-font/icons/dimmed_headlights.svg';
import front_fog_lights from 'rrg-font/icons/front_fog_lights.svg';
import no_lights from 'rrg-font/icons/no_lights.svg';
import very_happy from 'rrg-font/icons/very_happy.svg';
import happy from 'rrg-font/icons/happy.svg';
import undecided from 'rrg-font/icons/undecided.svg';
import not_happy from 'rrg-font/icons/not_happy.svg';
import angry from 'rrg-font/icons/angry.svg';
import surprise from 'rrg-font/icons/surprise.svg';
import cool from 'rrg-font/icons/cool.svg';
import garantee from 'rrg-font/icons/garantee.svg';
import prise from 'rrg-font/icons/prise.svg';
import helmet from 'rrg-font/icons/helmet.svg';
import joystick from 'rrg-font/icons/joystick.svg';
import sunny from 'rrg-font/icons/sunny.svg';
import windy from 'rrg-font/icons/windy.svg';
import cloudy_1 from 'rrg-font/icons/cloudy_1.svg';
import cloudy_2 from 'rrg-font/icons/cloudy_2.svg';
import raining_1 from 'rrg-font/icons/raining_1.svg';
import raining_2 from 'rrg-font/icons/raining_2.svg';
import lightning from 'rrg-font/icons/lightning.svg';
import partly_cloudy_1 from 'rrg-font/icons/partly_cloudy_1.svg';
import partly_cloudy_2 from 'rrg-font/icons/partly_cloudy_2.svg';
import partly_raining_1 from 'rrg-font/icons/partly_raining_1.svg';
import artly_raining_2 from 'rrg-font/icons/artly_raining_2.svg';
import partly_lightning_1 from 'rrg-font/icons/partly_lightning_1.svg';
import partly_lightning_2 from 'rrg-font/icons/partly_lightning_2.svg';
import snowing from 'rrg-font/icons/snowing.svg';
import partly_snowing_1 from 'rrg-font/icons/partly_snowing_1.svg';
import partly_snowing_2 from 'rrg-font/icons/partly_snowing_2.svg';
import snowy from 'rrg-font/icons/snowy.svg';

export const svgArray = [
    {svg: read, name: "read"},
    {svg: log_out, name: "log_out"},
    {svg: save, name: "save"},
    {svg: print, name: "print"},
    {svg: send, name: "send"},
    {svg: file_download, name: "file_download"},
    {svg: folder, name: "folder"},
    {svg: files, name: "files"},
    {svg: sheet, name: "sheet"},
    {svg: list_sheet, name: "list_sheet"},
    {svg: faourite_sheet, name: "faourite_sheet"},
    {svg: car_sheet, name: "car_sheet"},
    {svg: email_sheet, name: "email_sheet"},
    {svg: pdf_sheet, name: "pdf_sheet"},
    {svg: settings_sheet, name: "settings_sheet"},
    {svg: pdf_sheet, name: "pdf_sheet"},
    {svg: cookies, name: "cookies"},
    {svg: usb, name: "usb"},
    {svg: reload, name: "reload"},
    {svg: refresh, name: "refresh"},
    {svg: edit, name: "edit"},
    {svg: check_list, name: "check_list"},
    {svg: bullet_list, name: "bullet_list"},
    {svg: check_list_ok, name: "check_list_ok"},
    {svg: ok, name: "ok"},
    {svg: no, name: "no"},
    {svg: supDelete, name: "delete"},
    {svg: apps, name: "apps"},
    {svg: app, name: "app"},
    {svg: home, name: "home"},
    {svg: settings, name: "settings"},{svg: filter_1, name: "filter_1"},
    {svg: filter_2, name: "filter_2"},
    {svg: favourite, name: "favourite"},
    {svg: bookmark, name: "bookmark"},
    {svg: new_bookmark, name: "new_bookmark"},
    {svg: view, name: "view"},
    {svg: search, name: "search"},
    {svg: zoom, name: "zoom"},
    {svg: deZoom, name: "de-zoom"},
    {svg: increase_text, name: "increase_text"},
    {svg: decrease_text, name: "decrease_text"},
    {svg: touch, name: "touch"},
    {svg: menu, name: "menu"},
    {svg: add, name: "add"},
    {svg: more, name: "more"},
    {svg: alert, name: "alert"},
    {svg: information, name: "information"},
    {svg: question, name: "question"},
    {svg: comment, name: "comment"},
    {svg: dialog, name: "dialog"},
    {svg: user, name: "user"},
    {svg: discussion, name: "discussion"},
    {svg: group, name: "group"},
    {svg: contact, name: "contact"},
    {svg: idea, name: "idea"},
    {svg: photo, name: "photo"},
    {svg: video, name: "video"},
    {svg: play, name: "play"},
    {svg: pause, name: "pause"},
    {svg: stop, name: "stop"},
    {svg: previous, name: "previous"},
    {svg: next, name: "next"},
    {svg: minimize, name: "minimize"},
    {svg: full_screen, name: "full_screen"},
    {svg: expand, name: "expand"},
    {svg: soud, name: "soud"},
    {svg: no_sound, name: "no_sound"},
    {svg: music, name: "music"},
    {svg: podcast, name: "podcast"},
    {svg: download, name: "download"},
    {svg: upload, name: "upload"},
    {svg: links, name: "links"},
    {svg: link_out, name: "link_out"},
    {svg: share_1, name: "share_1"},
    {svg: share_2, name: "share_2"},
    {svg: arrow_1_left, name: "arrow_1_left"},
    {svg: arrow_1_right, name: "arrow_1_right"},
    {svg: arrow_1_up, name: "arrow_1_up"},
    {svg: arrow_1_down, name: "arrow_1_down"},
    {svg: arrow_1_up_left, name: "arrow_1_up_left"},
    {svg: arrow_1_up_right, name: "arrow_1_up_right"},
    {svg: arrow_1_down_right, name: "arrow_1_down_right"},
    {svg: arrow_1_down_left, name: "arrow_1_down_left"},
    {svg: arrow_2_left, name: "arrow_2_left"},
    {svg: arrow_2_right, name: "arrow_2_right"},
    {svg: arrow_2_up, name: "arrow_2_up"},
    {svg: arrow_2_down, name: "arrow_2_down"},
    {svg: arrow_2_up_left, name: "arrow_2_up_left"},
    {svg: arrow_2_up_right, name: "arrow_2_up_right"},
    {svg: arrow_2_down_right, name: "arrow_2_down_right"},
    {svg: arrow_2_down_left, name: "arrow_2_down_left"},
    {svg: slide, name: "slide"},
    {svg: scroll, name: "scroll"},
    {svg: phone, name: "phone"},
    {svg: mobile, name: "mobile"},
    {svg: tablet, name: "tablet"},
    {svg: laptop, name: "laptop"},
    {svg: desktop, name: "desktop"},
    {svg: television, name: "television"},
    {svg: wifi, name: "wifi"},
    {svg: carSend, name: "car-send"},
    {svg: car_received, name: "car_received"},
    {svg: car_ko, name: "car_ko"},
    {svg: car_ok, name: "car_ok"},
    {svg: calendar, name: "calendar"},
    {svg: reminder, name: "reminder"},
    {svg: alarm, name: "alarm"},
    {svg: stopwatch, name: "stopwatch"},
    {svg: answer_in_4_hours, name: "answer_in_4_hours"},
    {svg: data, name: "data"},
    {svg: chart, name: "chart"},
    {svg: organisation, name: "organisation"},
    {svg: planet, name: "planet"},
    {svg: factory, name: "factory"},
    {svg: foundy, name: "foundy"},
    {svg: basket, name: "basket"},
    {svg: credit_card, name: "credit_card"},
    {svg: euro, name: "euro"},
    {svg: money, name: "money"},
    {svg: coins, name: "coins"},
    {svg: reset_coins, name: "reset_coins"},
    {svg: saing, name: "saing"},
    {svg: calculator, name: "calculator"},
    {svg: promo, name: "promo"},
    {svg: sales, name: "sales"},
    {svg: shop_bag, name: "shop_bag"},
    {svg: box, name: "box"},
    {svg: car_servicing, name: "car_servicing"},
    {svg: welcom, name: "welcom"},
    {svg: carrestitution, name: "carrestitution"},
    {svg: car_dealership, name: "car_dealership"},
    {svg: safety, name: "safety"},
    {svg: tailored, name: "tailored"},
    {svg: flag, name: "flag"},
    {svg: target, name: "target"},
    {svg: pannel, name: "pannel"},
    {svg: compas, name: "compas"},
    {svg: pin, name: "pin"},
    {svg: motorway, name: "motorway"},
    {svg: countryside, name: "countryside"},
    {svg: city, name: "city"},
    {svg: parking, name: "parking"},
    {svg: mobility_help, name: "mobility_help"},
    {svg: tap_gesture_1, name: "tap_gesture_1"},
    {svg: tap_gesture_2, name: "tap_gesture_2"},
    {svg: tap_gesture_3, name: "tap_gesture_3"},
    {svg: double_tab_gesture_1, name: "double_tab_gesture_1"},
    {svg: double_tab_gesture_2, name: "double_tab_gesture_2"},
    {svg: double_tab_gesture_3, name: "double_tab_gesture_3"},
    {svg: swipe_gesture_1, name: "swipe_gesture_1"},
    {svg: swipe_gesture_2, name: "swipe_gesture_2"},
    {svg: swipe_gesture_3, name: "swipe_gesture_3"},
    {svg: hold_gesture_1, name: "hold_gesture_1"},
    {svg: hold_gesture_2, name: "hold_gesture_2"},
    {svg: hold_gesture_3, name: "hold_gesture_3"},
    {svg: scroll_gesture_1, name: "scroll_gesture_1"},
    {svg: scroll_gesture_2, name: "scroll_gesture_2"},
    {svg: scroll_gesture_3, name: "scroll_gesture_3"},
    {svg: scale_gesture_1, name: "scale_gesture_1"},
    {svg: scale_gesture_2, name: "scale_gesture_2"},
    {svg: scale_gesture_3, name: "scale_gesture_3"},
    {svg: drag_gesture_1, name: "drag_gesture_1"},
    {svg: drag_gesture_2, name: "drag_gesture_2"},
    {svg: drag_gesture_3, name: "drag_gesture_3"},
    {svg: split_and_rotate_gesture_1, name: "split_and_rotate_gesture_1"},
    {svg: split_and_rotate_gesture_2, name: "split_and_rotate_gesture_2"},
    {svg: split_and_rotate_gesture_3, name: "split_and_rotate_gesture_3"},
    {svg: eco_2_chart, name: "eco_2_chart"},
    {svg: eco_2, name: "eco_2"},
    {svg: fuel_station, name: "fuel_station"},
    {svg: fuel_excellent, name: "fuel_excellent"},
    {svg: fuel_very_good, name: "fuel_very_good"},
    {svg: fuel_good, name: "fuel_good"},
    {svg: fuel_low, name: "fuel_low"},
    {svg: fuel_very_low, name: "fuel_very_low"},
    {svg: no_fuel, name: "no_fuel"},
    {svg: fuel_compare, name: "fuel_compare"},
    {svg: hybrid, name: "hybrid"},
    {svg: plugging_hybrid, name: "plugging_hybrid"},
    {svg: battery, name: "battery"},
    {svg: battery_leasing, name: "battery_leasing"},
    {svg: electric_power, name: "electric_power"},
    {svg: electric_plug, name: "electric_plug"},
    {svg: not_plugged, name: "not_plugged"},
    {svg: plugged_ok, name: "plugged_ok"},
    {svg: standard_charging_port, name: "standard_charging_port"},
    {svg: rapide_charging_port, name: "rapide_charging_port"},
    {svg: wall_charging_port, name: "wall_charging_port"},
    {svg: electric_vehicule, name: "electric_vehicule"},
    {svg: battery_excellent, name: "battery_excellent"},
    {svg: battery_very_good, name: "battery_very_good"},
    {svg: battery_good, name: "battery_good"},
    {svg: battery_low, name: "battery_low"},
    {svg: battery_very_low, name: "battery_very_low"},
    {svg: no_battery, name: "no_battery"},
    {svg: engine, name: "engine"},
    {svg: electric_engine, name: "electric_engine"},
    {svg: kw_engine, name: "kw_engine"},
    {svg: oil_engine, name: "oil_engine"},
    {svg: engine_capacity, name: "engine_capacity"},
    {svg: car, name: "car"},
    {svg: car_park, name: "car_park"},
    {svg: car_range, name: "car_range"},
    {svg: hatchback, name: "hatchback"},
    {svg: sedan, name: "sedan"},
    {svg: etate, name: "etate"},
    {svg: mpv, name: "mpv"},
    {svg: suv, name: "suv"},
    {svg: pickUp, name: "pick-up"},
    {svg: coupe, name: "coupe"},
    {svg: roadster, name: "roadster"},
    {svg: race, name: "race"},
    {svg: passenger_cdv, name: "passenger_cdv"},
    {svg: cdv, name: "cdv"},
    {svg: shuttle, name: "shuttle"},
    {svg: compact_van, name: "compact_van"},
    {svg: minibus, name: "minibus"},
    {svg: large_van, name: "large_van"},
    {svg: chassisCab, name: "chassis-cab"},
    {svg: dump_truck, name: "dump_truck"},
    {svg: campervan, name: "campervan"},
    {svg: acceleration, name: "acceleration"},
    {svg: braking, name: "braking"},
    {svg: prgression, name: "prgression"},
    {svg: renault_rent, name: "renault_rent"},
    {svg: second_hand, name: "second_hand"},
    {svg: refresh_configuration, name: "refresh_configuration"},
    {svg: start_again, name: "start_again"},
    {svg: interior, name: "interior"},
    {svg: custom, name: "custom"},
    {svg: favourite_car, name: "favourite_car"},
    {svg: intervention, name: "intervention"},
    {svg: mergency, name: "mergency"},
    {svg: crash, name: "crash"},
    {svg: breakdown, name: "breakdown"},
    {svg: car_elevator, name: "car_elevator"},
    {svg: car_review, name: "car_review"},
    {svg: draining, name: "draining"},
    {svg: mechanical_1, name: "mechanical_1"},
    {svg: mechanical_2, name: "mechanical_2"},
    {svg: spray_gun, name: "spray_gun"},
    {svg: paint_bucket, name: "paint_bucket"},
    {svg: spray, name: "spray"},
    {svg: spare_parts, name: "spare_parts"},
    {svg: car_body, name: "car_body"},
    {svg: type_and_rim, name: "type_and_rim"},
    {svg: braking_1, name: "braking_1"},
    {svg: tyre, name: "tyre"},
    {svg: check_tyre, name: "check_tyre"},
    {svg: pumping, name: "pumping"},
    {svg: damper, name: "damper"},
    {svg: piston, name: "piston"},
    {svg: double_piston, name: "double_piston"},
    {svg: timing_belt, name: "timing_belt"},
    {svg: oil_filter, name: "oil_filter"},
    {svg: exhaust, name: "exhaust"},
    {svg: check_oil, name: "check_oil"},
    {svg: cooling_liquid, name: "cooling_liquid"},
    {svg: steering_wheel, name: "steering_wheel"},
    {svg: gearbox_1, name: "gearbox_1"},
    {svg: gearbox_2, name: "gearbox_2"},
    {svg: gearbox_3, name: "gearbox_3"},
    {svg: gearbox_4, name: "gearbox_4"},
    {svg: weight, name: "weight"},
    {svg: keys, name: "keys"},
    {svg: sellerie, name: "sellerie"},
    {svg: air_conditionning, name: "air_conditionning"},
    {svg: speeometer, name: "speeometer"},
    {svg: windshield, name: "windshield"},
    {svg: windshield_wipers, name: "windshield_wipers"},
    {svg: windshield_wipers_ok, name: "windshield_wipers_ok"},
    {svg: door, name: "door"},
    {svg: drame, name: "drame"},
    {svg: snow_box, name: "snow_box"},
    {svg: box_1, name: "box_1"},
    {svg: luggages, name: "luggages"},
    {svg: payload, name: "payload"},
    {svg: bike, name: "bike"},
    {svg: headlights, name: "headlights"},
    {svg: dimmed_headlights, name: "dimmed_headlights"},
    {svg: front_fog_lights, name: "front_fog_lights"},
    {svg: no_lights, name: "no_lights"},
    {svg: very_happy, name: "very_happy"},
    {svg: happy, name: "happy"},
    {svg: undecided, name: "undecided"},
    {svg: not_happy, name: "not_happy"},
    {svg: angry, name: "angry"},
    {svg: surprise, name: "surprise"},
    {svg: cool, name: "cool"},
    {svg: garantee, name: "garantee"},
    {svg: prise, name: "prise"},
    {svg: helmet, name: "helmet"},
    {svg: joystick, name: "joystick"},
    {svg: sunny, name: "sunny"},
    {svg: windy, name: "windy"},
    {svg: cloudy_1, name: "cloudy_1"},
    {svg: cloudy_2, name: "cloudy_2"},
    {svg: raining_1, name: "raining_1"},
    {svg: raining_2, name: "raining_2"},
    {svg: lightning, name: "lightning"},
    {svg: partly_cloudy_1, name: "partly_cloudy_1"},
    {svg: partly_cloudy_2, name: "partly_cloudy_2"},
    {svg: partly_raining_1, name: "partly_raining_1"},
    {svg: artly_raining_2, name: "artly_raining_2"},
    {svg: partly_lightning_1, name: "partly_lightning_1"},
    {svg: partly_lightning_2, name: "partly_lightning_2"},
    {svg: snowing, name: "snowing"},
    {svg: partly_snowing_1, name: "partly_snowing_1"},
    {svg: partly_snowing_2, name: "partly_snowing_2"},
    {svg: snowy, name: "snowy"}
];
