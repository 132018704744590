import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { isWidget, toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import WidgetToolbarRepository from "@ckeditor/ckeditor5-widget/src/widgettoolbarrepository";

import InsertRhSwiperCommand from './insertRhSwiperCommand';
import InsertRhSwiperSlideCommand from './insertRhSwiperSlideCommand';
import MoveSlideUpCommand from "./moveSlideUpCommand";
import MoveSlideDownCommand from "./moveSlideDownCommand";
import './theme/style.css';
import DeleteRhSwiperSlideCommand from "./deleteRhSwiperSlideCommand";

export default class RhSwiperEditing extends Plugin {
    static get requires() {                                                    // ADDED
        return [ Widget, WidgetToolbarRepository ];
    }
    init() {
        this._defineSchema();                                                  // ADDED
        this._defineConverters();                                                  // ADDED
        this.editor.commands.add( 'insertRhSwiper', new InsertRhSwiperCommand( this.editor ) );
        this.editor.commands.add( 'insertRhSwiperSlide', new InsertRhSwiperSlideCommand( this.editor ) );
        this.editor.commands.add( 'deleteRhSwiperSlide', new DeleteRhSwiperSlideCommand( this.editor ) );
        this.editor.commands.add( 'moveSlideUpCommand', new MoveSlideUpCommand( this.editor ) );
        this.editor.commands.add( 'moveSlideDownCommand', new MoveSlideDownCommand( this.editor ) );
    }

    afterInit() {
        const widgetToolbarRepository = this.editor.plugins.get( WidgetToolbarRepository );

        widgetToolbarRepository.register( 'swiper-slider-bar', {
            items: ['RhSwiperRemove', 'RhSwiperAdd', '|', 'RhSwiperUp', 'RhSwiperDown'],
            getRelatedElement: this.getClosestSelectedSwiperSlideWidget
        } );
    }

    _defineSchema() {                                                          // ADDED
        const schema = this.editor.model.schema;

        schema.register( 'RhSwiper', {
            isObject: true,
            allowWhere: '$block'
        } );

        schema.register( 'RhSwiperSlide', {
            isLimit: true,
            allowIn: 'RhSwiper',
            allowChildren: ['imageBlock', 'rawHtml']
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        // <RhSwiper> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'RhSwiper',
            view: {
                name: 'div',
                classes: 'swiper',
            }
        } );
        conversion.for( 'dataDowncast' ).elementToStructure( {
            model: 'RhSwiper',
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( 'div', { class: 'swiper', id: 'slider' + (new Date()).getTime() }, [
                    viewWriter.createContainerElement('div', {class:'swiper-wrapper'}, [
                        viewWriter.createSlot(node => node.is( 'element', 'RhSwiperSlide' )),
                    ]),
                    viewWriter.createContainerElement('div', {class:'swiper-pagination'}),
                    viewWriter.createContainerElement('div', {class:'swiper-button-prev'}, [
                        viewWriter.createContainerElement('i', {class:'icon-arrow_1_left icon-m'})
                    ]),
                    viewWriter.createContainerElement('div', {class:'swiper-button-next'}, [
                        viewWriter.createContainerElement('i', {class:'icon-arrow_1_right icon-m'})
                    ]),
                ] );
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'RhSwiper',
            view: ( modelElement, { writer: viewWriter } ) => {
                const viewElement = viewWriter.createContainerElement( 'div', { class: 'RhSwiper', id: 'slider' + (new Date()).getTime() } );
                viewWriter.setCustomProperty( 'swiper', true, viewElement );
                return toWidget(
                    viewElement,
                    viewWriter,
                    { label: 'RhSwiper Widget' }
                );
            }
        } );

        // <RhSwiperSlide> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'RhSwiperSlide',
            view: {
                name: 'div',
                classes: 'swiper-slide',
            }
        } );
        conversion.for( 'dataDowncast' ).elementToStructure( {
            model: 'RhSwiperSlide',
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createContainerElement( 'div', { class: 'swiper-slide' }, [
                    viewWriter.createSlot(node => node.is( 'element', 'imageBlock' ) || node.is( 'element', 'rawHtml' )),
                ] );
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'RhSwiperSlide',
            view: ( modelElement, { writer: viewWriter } ) => {
                const viewElement = viewWriter.createEditableElement( 'div', { class: 'RhSwiperSlide' } );
                viewWriter.setCustomProperty( 'swiper-slide', true, viewElement );
                return toWidgetEditable( viewElement, viewWriter );
            }
        } );
    }

    getClosestSelectedSwiperSlideWidget( selection ) {
        const selectionPosition = selection.getFirstPosition();

        if ( !selectionPosition ) {
            return null;
        }

        const viewElement = selection.getSelectedElement();
        if ( viewElement && (!!viewElement.getCustomProperty( 'swiper-slide' ) && viewElement.hasClass('ck-editor__editable')) ) {
            return viewElement;
        }

        let parent = selectionPosition.parent;

        while ( parent ) {
            if ( parent.is( 'element' ) && (!!parent.getCustomProperty( 'swiper-slide' ) && parent.hasClass('ck-editor__editable')) ) {
                return parent;
            }

            parent = parent.parent;
        }

        return null;
    }
}
