export function createRhSwiper( writer ) {
    const RhSwiper = writer.createElement( 'RhSwiper' );

    writer.append( createRhSlide(writer), RhSwiper );

    return RhSwiper;
}

export function createRhSlide( writer ) {
    const RhSwiperSlide = writer.createElement( 'RhSwiperSlide' );

    writer.appendElement( 'rawHtml', RhSwiperSlide );

    return RhSwiperSlide;
}
