import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {addListToDropdown, createDropdown} from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import {svgArray} from './icons';
import 'rrg-font/RenaultIcon/style.css'
import PlaceholderCommand from './IconPluginCommand';
import {toWidget} from '@ckeditor/ckeditor5-widget/src/utils';

export default class IconPlugin extends Plugin {

    _defineSchema() {
        const schema = this.editor.model.schema;
        schema.register( 'rhIcon', {
            // Allow wherever text is allowed:
            allowWhere: '$text',
            // The placeholder will act as an inline node:
            isInline: true,
            // The inline widget is self-contained so it cannot be split by the caret and can be selected:
            isObject: true,
            // The inline widget can have the same attributes as text (for example linkHref, bold).
            allowAttributesOf: '$text',
            // The placeholder can have many types, like date, name, surname, etc:
            allowAttributes: ['name', 'style']
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        conversion.for('upcast').elementToElement( {
            view: {
                name: 'span',
                classes: ['icon']
            },
            model: (viewElement, { writer: modelWriter }) => {
                // Extract the "name" from "{name}".
                return modelWriter.createElement( 'rhIcon', {name: [...viewElement._classes][1].split('-')[1]});
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'rhIcon',
            view: (modelItem, { writer: viewWriter }) => {
                const widgetElement = createPlaceholderView(modelItem, viewWriter);

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            }
        } );

        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'rhIcon',
            view: ( modelItem, { writer: viewWriter } ) => createPlaceholderView( modelItem, viewWriter )
        } );

        // Helper method for both downcast converters.
        function createPlaceholderView( modelItem, viewWriter ) {
            const name = modelItem.getAttribute('name');
            return viewWriter.createContainerElement('span', {
                class: `icon icon-${name}`
            }, {
                isAllowedInsideAttributeElement: true
            });
        }

    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( 'rhIcon', new PlaceholderCommand(this.editor));

        const editor = this.editor;

        // Register UI component
        editor.ui.componentFactory.add('insertIcon', locale => {
            const dropdownView = createDropdown(locale);
            dropdownView.buttonView.set({
                label: 'Insérer un Icon',
                icon: svgArray[0].svg,
                tooltip: true
            });

            // The collection of the list items.
            const items = new Collection();

            for (const iconSvg of svgArray) {
                items.add( {
                    type: 'button',
                    model: new Model({
                        label: iconSvg.name,
                        class: iconSvg.name,
                        icon: iconSvg.svg
                    })
                });
            }

            // Create a dropdown with a list inside the panel.
            addListToDropdown(dropdownView, items);

            dropdownView.on('execute', eventInfo => {
                const { class: className } = eventInfo.source;
                editor.execute( 'rhIcon', { value: className } );
            } );

            return dropdownView;
        });
    }
}
