export function createRhDropdown( writer ) {
    const RhDropdown = writer.createElement( 'RhDropdown' );
    const RhDropdownHandler = writer.createElement( 'RhDropdownHandler' );
    const RhDropdownTitle = writer.createElement( 'RhDropdownTitle' );
    const RhDropdownContent = writer.createElement( 'RhDropdownContent' );

    writer.append( RhDropdownHandler, RhDropdown );
    writer.append( RhDropdownTitle, RhDropdownHandler );
    writer.append( RhDropdownContent, RhDropdown );

    // There must be at least one paragraph for the description to be editable.
    // See https://github.com/ckeditor/ckeditor5/issues/1464.
    writer.appendElement( 'paragraph', RhDropdownContent );

    return RhDropdown;
}
